<template>
  <other-scars-template
    :mishap-scars-size="mishapScarsSize"
    :title="scarsSizeTitle"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import OtherScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/other-scars/OtherScarsTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { getLocalizedScarTypes } from '@/modules/questionnaire/utils';

const { requiredField, field } = fieldBuilder;

const FIELDS = [requiredField('mishapScarsSize'), field('acneScarCauses')];

export default {
  name: 'OtherScars',
  components: {
    OtherScarsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    scarsSizeTitle() {
      return getLocalizedScarTypes(this.$i18n, this.acneScarCauses);
    }
  },
  watch: {
    mishapScarsSize() {
      this.showNextStep();
    }
  }
};
</script>
